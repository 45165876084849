import axios from 'axios'
import React, { createContext, useContext } from 'react'

import { GlobalContext } from './GlobalContext'
import { API_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const { setupAxiosInterceptor, idm, idmToken, setIdmToken } = useContext(GlobalContext)

  const fetchCurrentUser = async () => {
    if (idmToken) {
      setupAxiosInterceptor(idmToken)
      try {
        const { data } = await axios.get(`${API_URL}/me`)
        return data
      } catch (err) {
        if (err?.response?.status === 401) {
          setIdmToken(null)
        }
        return null
      }
    }
  }

  const logoutHandler = () => {
    // logout of IDM
    idm.authenticator.logout()
    setIdmToken(null)
    window.location.href = '/'
  }

  const userContextValues = {
    idmToken,
    fetchCurrentUser,
    logoutHandler,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
