import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { App } from './App'
import { FeatureContextProvider } from './contexts/FeatureContext'
import { GlobalContextProvider } from './contexts/GlobalContext'
import { UserContextProvider } from './contexts/UserContext'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <FeatureContextProvider>
        <GlobalContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </GlobalContextProvider>
      </FeatureContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
