import { Spin } from 'antd'
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from './components/layout/Header'
import { MobileMenu } from './components/layout/MobileMenu'

export const SuperBase = () => {
  return (
    <Wrapper>
      <Header />
      <MobileMenu />
      <Suspense
        fallback={
          <div className='loading'>
            <Spin />
          </div>
        }>
        <Outlet />
      </Suspense>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .loading {
    display: flex;
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
