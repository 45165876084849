import {
  NotificationOutlined,
  UserAddOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  UserOutlined,
  ToolOutlined,
  LogoutOutlined,
} from '@ant-design/icons'

export const routes = [
  {
    path: '/campaigns',
    label: 'Campaigns',
    icon: <NotificationOutlined />,
  },
  {
    path: '/opt-ins',
    label: 'Opt-Ins',
    icon: <UserAddOutlined />,
  },
  {
    path: '/brands',
    label: 'Brands',
    icon: <TeamOutlined />,
  },
  {
    path: '/users',
    label: 'Users',
    icon: <UserOutlined />,
  },
  {
    path: '/payments',
    label: 'Payments',
    icon: <DollarCircleOutlined />,
  },
  {
    path: '/utility',
    label: 'Utility',
    icon: <ToolOutlined />,
  },
  {
    path: '/logout',
    label: 'Sign Out',
    className: 'logout',
    icon: <LogoutOutlined />,
  },
]
