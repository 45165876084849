import { Result } from 'antd'
import React from 'react'
import styled from 'styled-components'
export default function NoAccess() {
  return (
    <Wrapper>
      <div className='no-access'>
        <Result
          status='403'
          title='403'
          subTitle={
            <>
              Sorry, you do not have permission to access this dashboard.
              <br />
              If you believe this is a mistake, please contact our engineering team or return to the
              previous page.
            </>
          }
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .no-access {
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 50px;
  }
`
